import React from "react";
import ReactDOM from "react-dom/client";
import $ from "jquery";
import { Provider } from "react-redux";
import { persistedStore } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import store from "./store/index";
import { AppProvider } from "./contexts/appContexts";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.NODE_ENV === "production") {
  $("html").on("contextmenu", function (e) {
    return false;
  });
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};

  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === "function"
          ? () => {}
          : null;
    }
  }
}

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistedStore}>
      <AppProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppProvider>
    </PersistGate>
  </Provider>
);
