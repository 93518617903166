const DATA_KEY = "bqwOlSSbg9VLtQuMp3mB7OAWQQwrvj6V";
let apiBaseURL = "https://server.tradex247.com/api/v1";
let xSocketURL = "https://server.tradex247.com";

const xSocketOptions = {
  "force new connection": true,
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 1000,
  reconnectionAttempt: "Infinity",
  transports: ["websocket"],
};

if (process.env.NODE_ENV !== "development") {
  apiBaseURL = "https://server.tradex247.com/api/v1";
  xSocketURL = "https://server.tradex247.com";
}

export { apiBaseURL, DATA_KEY, xSocketURL, xSocketOptions };
