import { authAction } from "./auth-slice";
import aes256 from "aes256";
import { DATA_KEY } from "../../config/constance";
import { onSuccess, onFailedMsg } from "../../helper/showHideAlert";
import { setUserProfile } from "../user/user-slice";

function onLogoutHandlerFun(dispatch) {
  dispatch(authAction.onSetLogoutHandler());
  dispatch(
    setUserProfile({
      user: {
        fullName: "User",
        isVerify: false,
        mobileNumber: "",
        role: "user",
        isPasswordChanged: false,
        balance: 0,
      },
    })
  );
}

export const userLogoutOnSessionExpired = (navigate, msg) => {
  return (dispatch) => {
    localStorage.clear();
    onLogoutHandlerFun(dispatch);
    onFailedMsg(dispatch, msg ? msg : "Session Expired.");
    navigate("/login");
  };
};

export const changePasswordHandler = (body, navigate, authFetch) => {
  return async (dispatch) => {
    try {
      let { data } = await authFetch.post(`/user/change-password`, body);
      if (data.status === true) {
        localStorage.clear();
        dispatch(authAction.onToggleResetPasswordModalStatus(false));
        onLogoutHandlerFun(dispatch);
        onSuccess(dispatch, data?.message);
        navigate("/login");
      } else {
        onFailedMsg(dispatch, data?.message);
      }
    } catch (error) {
      onFailedMsg(
        dispatch,
        error?.response?.data?.message || "Password dose not match"
      );
    } finally {
      return true;
    }
  };
};

export const onLogoutHandler = (navigate, authFetchGET) => {
  return async (dispatch) => {
    try {
      const { data } = await authFetchGET.get(`/user/logout`);
      if (data && data.status === true) {
        localStorage.clear();
        onLogoutHandlerFun(dispatch);
        navigate("/login");
        onSuccess(dispatch, data?.message);
      }
    } catch (e) {
      localStorage.clear();
      onLogoutHandlerFun(dispatch);
      navigate("/login");
    } finally {
      return true;
    }
  };
};

export const onUserLogin = (body, authFetch, navigate) => {
  return async (dispatch) => {
    dispatch(authAction.onLoginRequest());
    try {
      const { data } = await authFetch.post("/user/login", body);
      if (data.status === true) {
        dispatch(authAction.onLoginSuccess({ data: data }));
        const descryptJson = aes256.decrypt(DATA_KEY, data.data);
        let resposne1 = JSON.parse(descryptJson);
        let userData = JSON.stringify(resposne1);
        let userId = resposne1.user_id;
        let ip = resposne1.ipAddress;
        let dateTime = resposne1.dateTime;
        let userData1 = aes256.encrypt(DATA_KEY, userData);
        let userId1 = aes256.encrypt(DATA_KEY, userId);
        let ip1 = aes256.encrypt(DATA_KEY, ip);
        let dateTime1 = aes256.encrypt(DATA_KEY, dateTime);

        localStorage.setItem("notification", "true");
        localStorage.setItem("userId", userId1);
        localStorage.setItem("token", data.token);

        // Proccess for Remember me
        // let remObj = {
        //   flag: body.remember,
        //   userName: body.remember === true ? body.userName : "",
        //   password: body.remember === true ? body.password : "",
        // };

        if (resposne1?.changePassword === true) {
          onSuccess(dispatch, "Login Successfully.");
          localStorage.setItem("userData", userData1);
          localStorage.setItem("ip", ip1);
          localStorage.setItem("dateTime", dateTime1);
          navigate("/home");
        } else {
          dispatch(authAction.onToggleResetPasswordModalStatus());
        }
      } else {
        dispatch(authAction.onLoginFailed());
        onFailedMsg(dispatch, data.message);
      }
    } catch (e) {
      dispatch(authAction.onLoginFailed());
    }
  };
};

// export const onChangePasswordHandlerAction = (body, authFetch) => {
//   return async (dispatch) => {
//     try {
//       const { data } = await authFetch.put(
//         `/user/resetpassword/${body.id}`,
//         body
//       );
//       if (data && data?.status === true) {
//         onSuccess(dispatch, data?.message);
//         return true;
//       } else {
//         onFailedMsg(dispatch, data?.message);
//       }
//     } catch (e) {
//       onFailedMsg(dispatch, "Error");
//     }
//   };
// };
