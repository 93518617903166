import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProtectedRoute, MainPage } from "./Routes";
import { AlertState } from "./Components/UI";
import { Dashboard, Login, PagenotFound, Users } from "./Pages";
import "./App.scss";

function App() {
  const { alertShow } = useSelector((state) => state.ui);

  return (
    <>
      {alertShow && <AlertState />}
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="/dashboard" replace />} />
          <Route path="dashboard" exact element={<Dashboard />} />
          <Route path="users" exact element={<Users />} />
        </Route>
        <Route path="/login" exact element={<Login />} />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
    </>
  );
}

export default App;
