import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Error, ShowHidePwd, Button, AppLogo } from "../../Components/UI";
import useAuthApp from "../../hooks/useAuthApp";
import { onSuccess, onFailedMsg } from "../../helper/showHideAlert";
import { authAction } from "../../store/auth/auth-slice";
import "../../Styled/Auth.scss";
import { onRememberMeHandlerMd } from "../../store/presist/presist-action";

const Login = () => {
  const { authFetch, navigate, dispatch } = useAuthApp();
  const { user } = useSelector((state) => state.auth);
  const { isRememberMe } = useSelector((state) => state.presist);
  const [btnLoading, setBtnLoading] = useState(false);

  const [values, setValues] = useState({
    mobileNumber: isRememberMe?.mobileNumber,
    contactDetails: {
      name: "",
      dialCode: "",
      countryCode: "",
      formattedValue: isRememberMe?.mobileNumber,
    },
    password: isRememberMe?.password,
    check: isRememberMe?.flag,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (user) {
      navigate("/dashboard ");
    } else {
      localStorage.clear();
    }
  }, [navigate, user]);

  const handleChangeMobile = (value, data, event, formattedValue) => {
    delete data?.format;
    setValues((previousState) => {
      return {
        ...previousState,
        mobileNumber: value.slice(data.dialCode.length),
        contactDetails: { ...data, formattedValue: formattedValue },
      };
    });
    setErrors((previousState) => {
      return {
        ...previousState,
        mobileNumber: formattedValue === "" ? "Mobile number is required" : "",
      };
    });
  };

  const onChnageValue = (e) => {
    setValues((previousState) => {
      return {
        ...previousState,
        [e.target.name]: e.target.value,
      };
    });

    setErrors((previousState) => {
      return {
        ...previousState,
        [e.target.name]:
          e.target.value === "" ? `${e.target.title} is required` : "",
      };
    });
  };

  const isFormValidate = () => {
    if (
      values?.contactDetails?.formattedValue === "" ||
      values.mobileNumber === "" ||
      !values.password
    ) {
      setErrors({
        ...errors,
        mobileNumber:
          values?.contactDetails?.formattedValue === ""
            ? "Mobile number is required"
            : values.mobileNumber === ""
            ? "Invalid Mobile Number"
            : "",
        password: !values.password ? "Password is required" : "",
      });
      return false;
    }
    return true;
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValidate() && !btnLoading) {
      setBtnLoading(true);
      try {
        let formBody = {
          mobileNumber: `${values.contactDetails?.dialCode}${values.mobileNumber}`,
          password: values.password,
        };
        const { data } = await authFetch.post("/user/admin/signin", formBody);
        if (data && data?.status === true) {
          onSuccess(dispatch, data?.message);
          dispatch(
            authAction.onUpdateUserToken(data?.accessToken || undefined)
          );
          await localStorage.setItem("token", data?.accessToken);
          if (!data?.isPasswordChanged) {
            await localStorage.setItem("update-password", true);
          }
          if (values.check) {
            let remObj = {
              flag: values.check,
              mobileNumber:
                values.contactDetails.dialCode + values.mobileNumber,
              password: values.password,
            };
            dispatch(onRememberMeHandlerMd(true, remObj));
          } else {
            dispatch(onRememberMeHandlerMd(false));
          }

          navigate("/dashboard");
        } else {
          onFailedMsg(dispatch, data?.message);
        }
      } catch (e) {
        onFailedMsg(dispatch, e?.response?.data?.message);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  return (
    <>
      <section className="account-from">
        <div className="account__wrapper">

         
            <AppLogo />
            <div className="account__header">
              <h2>
                <span>Log </span> in
              </h2>
              <p className="mb-0">Welcome back!</p>
            </div>
            {/* <SocialAuth />

            <div className="account__divider ">
              <span>or</span>
            </div> */}

            <form
              className="account__form needs-validation"
              autoComplete="off"
              onSubmit={onSubmit}
            >
              <div className="form-inpu_box">
                <label htmlFor="mobileNum" className="form-label">
                  WhatsApp Mobile Number
                </label>
                <PhoneInput
                  inputProps={{
                    name: "phone",
                    required: true,
                    id: "mobileNum",
                  }}
                  enableSearch={true}
                  country={"in"}
                  preferredCountries={["in"]}
                  value={values?.contactDetails?.formattedValue}
                  onChange={handleChangeMobile}
                  className="form-inpu_box mobile_box"
                />
                {errors?.mobileNumber && <Error>{errors?.mobileNumber}</Error>}
              </div>

              <div className="form-pass form-inpu_box">
                <label htmlFor="password" className="form-label">
                  Password
                </label>

                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control showhide-pass"
                  name="password"
                  id="password"
                  title="Password"
                  placeholder="Password"
                  value={values.password}
                  onChange={onChnageValue}
                  maxLength={25}
                />

                <ShowHidePwd
                  isShowPwd={!showPassword}
                  onTogglePassword={togglePassword}
                />
                {/* {errors && errors?.password && <p>{errors?.password}</p>} */}
                {errors?.password && <Error>{errors?.password}</Error>}
              </div>

              <div className="account__check">
                <div className="account__check-remember">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={values.check}
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        check: e.target.checked,
                      }))
                    }
                    id="rememberme"
                  />
                  <label
                    htmlFor="rememberme"
                    className="form-check-label remember-lable"
                  >
                    Remember me
                  </label>
                </div>
              </div>

              <Button
                type="submit"
                isDisabled={btnLoading}
                classes="trk-btn-box"
              >
                {btnLoading ? "Loading..." : "SIGN IN"}
              </Button>
            </form>
          </div>
      </section>
    </>
  );
};

export default Login;
