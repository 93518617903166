const SwitchIcon = ({ isActive, onClick }) => {
  const iconStyle = {
    transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
    cursor: "pointer",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27}
      height={27}
      viewBox="0 0 256 256"
      style={iconStyle}
      fill={isActive ? "#0290f4" : ""}
      onClick={onClick}
    >
      <path
        strokeMiterlimit={10}
        d="M36 11H14C6.281 11 0 17.281 0 25s6.281 14 14 14h22c7.719 0 14-6.281 14-14s-6.281-14-14-14zm0 26c-6.617 0-12-5.383-12-12s5.383-12 12-12 12 5.383 12 12-5.383 12-12 12z"
        fontFamily="none"
        fontSize="none"
        fontWeight="none"
        style={{
          mixBlendMode: "normal",
        }}
        textAnchor="none"
        transform="rotate(180 128 128) scale(5.12)"
      />
    </svg>
  );
};
export default SwitchIcon;
