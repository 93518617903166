import { uiActions } from './ui-slice';

export const toggleCart = () => {
  return async (dispatch) => {
    dispatch(uiActions.toggle())
  }
};

export const showAlertBox = (obj) => {
  return async (dispatch) => {
    dispatch(uiActions.showAlert({
      alertShow: obj.alertShow,
      alertType: obj.alertType,
      alertText: obj.alertText
    }))
  }
}

export const hideAlertBox = () => {
  return async (dispatch) => {
    dispatch(uiActions.hideAlert())
  }
};
