import { Fragment, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { FaUser } from "react-icons/fa";
import { DashboardBox } from "../Components/Layouts";
import { onFetchDashboardCount } from "../store/user/user-action";
import useAuthApp from "../hooks/useAuthApp";
import "../Styled/dashboard.scss";

const Dashboard = () => {
  const { authFetchGET, dispatch } = useAuthApp();
  const { dashboardCount } = useSelector((state) => state?.user);
  const authFetchGETRef = useRef(authFetchGET);

  const onFetchDashboardCountHandler = useCallback(() => {
    dispatch(onFetchDashboardCount(authFetchGETRef.current));
  }, [dispatch]);

  useEffect(() => {
    authFetchGETRef.current = authFetchGET;
  }, [authFetchGET]);

  useEffect(() => {
    onFetchDashboardCountHandler();
  }, [onFetchDashboardCountHandler]);

  return (
    <Fragment>
      <section className="main_section">
        <h2 className=" heding_box">Dashboard</h2>
        <div className="dashboard-box-body">
          <DashboardBox
            count={dashboardCount?.totalUsers || 0}
            url="/users"
            state={{ fromDate: "" }}
            label="All Users"
            className="alert-primary"
            icon={<FaUser />}
          />
        </div>
      </section>
    </Fragment>
  );
};

export default Dashboard;
