import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAlertBox } from "../../store/ui/ui-action";
import { IoMdCheckmark } from "react-icons/io";
import { IoIosWarning } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import "../../Styled/AlertState.scss";

const AlertState = () => {
  const dispatch = useDispatch();
  const { alertShow, alertType, alertText } = useSelector((state) => state.ui);

  const alertClass = alertType === "alert-danger" ? "error" : "success";
  const handleClose = () => {
    dispatch(hideAlertBox());
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(hideAlertBox());
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [alertShow, dispatch]);

  return (
    <>
      <div
        className={`toast-main ${
          alertShow ? "active" : "active"
        } ${alertClass}`}
      >
        <div className="toast-content">
          {alertType === "alert-danger" ? (
            <IoIosWarning fontSize={20} color="#fff" />
          ) : (
            <IoMdCheckmark fontSize={20} color="#fff" />
          )}

          <h6 className="message">{alertText}</h6>
          <RxCross2
            onClick={handleClose}
            cursor={"pointer"}
            fontSize={18}
            color="#fff"
          />
        </div>
      </div>
    </>
  );
};
export default AlertState;
