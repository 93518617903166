import { Fragment, useRef, useState, useEffect } from "react";
import useDataTable from "../hooks/useDataTable";
import { fixFloatNumber, formatDate } from "../helper/common";
import { SwitchIcon } from "../Components/UI";
import { ConfirmModal } from "../models";
import { changeActiveStatus } from "../store/user/user-action";
import useAuthApp from "../hooks/useAuthApp";
import "../Styled/detatable.scss";

const Users = () => {
  const { dispatch, authFetch } = useAuthApp();
  const tableRefs = useRef(null);
  const [filter] = useState({
    status: "All",
  });
  const [isStatusActive, setIsStatusActive] = useState({
    id: undefined,
    isActive: false,
    isOpen: false,
    loading: false,
  });

  let columns = [
    { data: "" },
    { data: "userName" },
    { data: "mobileNumber" },
    { data: "status" },
    { data: "balance" },
    { data: "date" },
    { data: "" },
  ];
  let columnDefs = [
    { orderable: false, targets: [0] },
    { orderable: false, targets: [1] },
    { orderable: false, targets: [2] },
    { orderable: false, targets: [3] },
    { orderable: false, targets: [4] },
    { orderable: false, targets: [5] },
    { orderable: false, targets: [6] },
  ];
  let dtBody = {
    status: filter.status,
    date: new Date(),
  };
  const { tableData, updateDatatableData, initializeDataTable } = useDataTable(
    tableRefs,
    columns,
    columnDefs,
    dtBody,
    "/user/admin/getAllUsers"
  );

  const onCloseActiveDeactiveModal = () => {
    setIsStatusActive({
      // id: undefined,
      isOpen: false,
      loading: false,
    });
  };

  const onChangeActiveStatus = async () => {
    let obj = {
      id: isStatusActive.id,
      status: isStatusActive.isActive,
    };
    setIsStatusActive((previousState) => {
      return {
        ...previousState,
        loading: true,
      };
    });

    let res = await dispatch(changeActiveStatus(obj, authFetch));
    console.log(res);
    if (res) {
      onCloseActiveDeactiveModal();
      const modifiedData = {
        ...tableData,
        data: tableData.data.map((item, index) => {
          if (item?._id === isStatusActive?.id) {
            return {
              ...item,
              isActive: !item.isActive,
            };
          }
          return item;
        }),
      };
      updateDatatableData(modifiedData);
    } else {
      setIsStatusActive((previousState) => {
        return {
          ...previousState,
          loading: false,
        };
      });
    }
  };

  useEffect(() => {
    initializeDataTable(true);
  }, []);

  useEffect(() => {
    let oddElements = document.getElementsByClassName("odd");
    if (tableData && tableData?.data?.length > 0) {
      for (let i = 0; i < oddElements.length; i++) {
        oddElements[i].style.display = "none";
      }
    }

    if (tableData && tableData?.data?.length === 0) {
      let getEmptyRows = document.getElementsByClassName("dataTables_empty");
      for (let i = 0; i < getEmptyRows.length; i++) {
        getEmptyRows[i].innerText = "NO RECORD FOUND";
      }
    }
  }, [tableData]);

  return (
    <Fragment>
      <section className="main_section">
        <h2 className=" heding_box">Users Page</h2>

        <div className="recentOrders">
          <table ref={tableRefs} className="report-card-box">
            <thead className="men-titel-color">
              <tr>
                <th>No.</th>
                <th className="text-left">Full Name</th>
                <th className="text-left">Mobile</th>
                <th className="text-left">Status</th>
                <th className="text-left">Balance</th>
                <th className="text-right">Date</th>
                <th className="text-right">Action</th>
              </tr>
            </thead>

            {tableData && tableData?.data?.length > 0 && (
              <tbody>
                {tableData?.data?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.fullName}</td>
                    <td>{item?.contactDetails?.formattedValue}</td>
                    <td>
                      {
                        <SwitchIcon
                          isActive={item?.isActive}
                          onClick={() =>
                            setIsStatusActive({
                              id: item?._id,
                              isActive: item?.isActive,
                              isOpen: true,
                            })
                          }
                        />
                      }
                    </td>
                    <td>{fixFloatNumber(item?.balance)}</td>
                    <td>{formatDate(item?.createdAt)}</td>
                    <td>-</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </section>

      {isStatusActive?.isOpen && (
        <ConfirmModal
          isOpen={isStatusActive?.isOpen}
          text="Are you sure want to change active status?"
          onConfirm={onChangeActiveStatus}
          onCancle={onCloseActiveDeactiveModal}
          modalTitle="Change Status"
          loading={isStatusActive.loading}
        />
      )}
    </Fragment>
  );
};

export default Users;
