import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import $ from "jquery";
import useAuthApp from "../../hooks/useAuthApp";
import { onLogoutHandler } from "../../store/auth/auth-action";
import { useEffect, useRef, useState } from "react";
import "../../Styled/Header.scss";
import { IoMdArrowDropdown } from "react-icons/io";
import { BiLogOutCircle } from "react-icons/bi";
import { CgMenuLeft } from "react-icons/cg";

const Header = () => {
  const userDropdownRef = useRef();
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  const { authFetchGET, navigate, dispatch } = useAuthApp();
  const [toggleClass, setToggleClass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    $(document).ready(function () {
      const $sidebar = $(".side-bar_bg");
      const $mainBody = $("#pageBody");
      if (toggleClass) {
        $sidebar.addClass("sidebar-open");
        $mainBody.css("overflow", "hidden");
      } else {
        $sidebar.removeClass("sidebar-open");
        $mainBody.css("overflow", "visible");
      }
      $sidebar.on("click", function () {
        $sidebar.toggleClass("sidebar-open");
      });
    }, []);
  }, [toggleClass]);

  const onToggleClass = () => {
    setToggleClass(!toggleClass);
  };

  const onLogoutHandlerfun = async () => {
    setLoading(true);
    const res = await dispatch(onLogoutHandler(navigate, authFetchGET));
    if (res) {
      setLoading(false);
    }
  };

  // user
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header className="header-section ">
        <div className="header-wrapper">
          <div className=" header-bar_icon" onClick={onToggleClass}>
            <CgMenuLeft fontSize={"25px"} />
          </div>

          <div></div>

          <div className="profile-container">
            <div
              className="profile-button"
              onClick={toggleDropdown}
              tabIndex="0"
            >
              <img src="/assets/images/user.png" alt="Profile" />
              <span className="user_name">
                {user?.fullName} <IoMdArrowDropdown />
              </span>
            </div>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                {/* <Link to="#" className="user_link">
                  {" "}
                  <RiShieldUserLine /> Profile
                </Link> */}
                {token && (
                  <Link
                    to="#"
                    onClick={onLogoutHandlerfun}
                    className="user_link"
                  >
                    <BiLogOutCircle />{" "}
                    <span> {loading ? "Loading." : "Logout"}</span>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
