import { useContext, createContext, useReducer } from "react";
import reducer from "./reducer";
import { ON_CONNECT_SOCKET } from "./actions";

const initialState = {
  socket: null,
};

const AppContext = createContext();
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onSocketConnect = (url, option) => {
    dispatch({
      type: ON_CONNECT_SOCKET,
      payload: { url, option },
    });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        onSocketConnect,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
