import { useEffect, useCallback, useRef } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { xSocketURL, xSocketOptions } from "../config/constance";
import { Header, Sidebar } from "../Components/Layouts";
import useAuthApp from "../hooks/useAuthApp";
import { authAction } from "../store/auth/auth-slice";
import { onFetchUserProfileHandler } from "../store/user/user-action";

const MainPage = () => {
  const { dispatch, authFetchGET } = useAuthApp();
  const authFetchGETRef = useRef(authFetchGET);
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    let heartbeatInterval;
    const XSocket = io(xSocketURL, xSocketOptions);
    if (XSocket && user?.user_id) {
      XSocket.emit("join-room", user?.user_id);
      XSocket.on("reconnect", () => {
        XSocket.emit("join-room", user?.user_id);
      });

      heartbeatInterval = setInterval(() => {
        XSocket.emit("heartbeat");
      }, 2000);

      XSocket.on("heartbeatResponse", () => {
        // console.log('Ping-pong');
      });
    }

    return () => {
      if (XSocket) {
        clearInterval(heartbeatInterval);
        XSocket.disconnect();
      }
    };
  }, [user, token]);

  const onfetchUserProfile = useCallback(() => {
    dispatch(onFetchUserProfileHandler(authFetchGETRef.current));
  }, [dispatch]);

  useEffect(() => {
    authFetchGETRef.current = authFetchGET;
  }, [authFetchGET]);

  useEffect(() => {
    onfetchUserProfile();
  }, [onfetchUserProfile]);

  useEffect(() => {
    let getIsUpdatePassword = localStorage.getItem("update-password");

    if (getIsUpdatePassword) {
      dispatch(authAction.onToggleResetPasswordModalStatus(true));
      localStorage.removeItem("update-password");
    }
  }, [token, dispatch, user]);

  return (
    <>
      <Header />
      <Sidebar />
      <Outlet />
    </>
  );
};
export default MainPage;
