import { uiActions } from "../ui/ui-slice";
import { onRequest, onSetDashboardCounter, setUserProfile } from "./user-slice";

export const onFetchUserProfileHandler = (authFetchGET) => {
  return async (dispatch) => {
    try {
      const { data } = await authFetchGET.get("/user/admin/profile");
      if (data && data?.status === true) {
        dispatch(setUserProfile({ user: data?.data }));
      } else {
        dispatch(
          setUserProfile({
            user: {
              fullName: "User",
              isVerify: false,
              mobileNumber: "",
              role: "user",
              isPasswordChanged: false,
              balance: 0,
            },
          })
        );
      }
    } catch (e) {
      dispatch(
        setUserProfile({
          user: {
            fullName: "User",
            isVerify: false,
            mobileNumber: "",
            role: "user",
            isPasswordChanged: false,
            balance: 0,
          },
        })
      );
    }
  };
};

export const onFetchDashboardCount = (authFetchGET) => {
  return async (dispatch) => {
    try {
      const { data } = await authFetchGET.get("/user/admin/dashboard-count");
      if (data.status === true) {
        dispatch(
          onSetDashboardCounter({
            counter: data.data,
          })
        );
      } else {
        dispatch(
          onSetDashboardCounter({
            counter: {
              totalUsers: 0,
            },
          })
        );
      }
    } catch (e) {
      dispatch(
        onSetDashboardCounter({
          counter: {
            totalUsers: 0,
          },
        })
      );
    }
  };
};

export const changeActiveStatus = (body, authFetch) => {
  return async (dispatch) => {
    let status = false;
    try {
      dispatch(onRequest());
      const { data } = await authFetch.put(
        `/user/admin/changeStatus/${body.id}`,
        body
      );
      if (data.status === true) {
        dispatch(
          uiActions.showAlert({
            alertShow: true,
            alertType: "alert-success",
            alertText: data.message,
          })
        );
        status = true;
      } else {
        dispatch(
          uiActions.showAlert({
            alertShow: true,
            alertType: "alert-danger",
            alertText: data.message,
          })
        );
      }
    } catch (e) {
      dispatch(
        uiActions.showAlert({
          alertShow: true,
          alertType: "alert-danger",
          alertText: e.response.data.message,
        })
      );
    } finally {
      return status;
    }
  };
};
