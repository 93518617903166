import aes256 from "aes256";
import CryptoJS from "crypto-js";
import { DATA_KEY } from "../config/constance";

// ******************************************************************************** //
// For Encryption
export function reverseString(str) {
  const splitString = str.split(""); // var splitString = "hello".split("");
  const reverseArray = splitString.reverse();
  const joinArray = reverseArray.join("");
  return joinArray;
}

function dataRkEnc(key, data) {
  const encrypted = aes256.encrypt(key, data);
  return encrypted;
}

export function getRandomString(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getEncKey(resObj) {
  const encKey = getRandomString(20);
  const appendEncKey = getRandomString(5);
  const finalString =
    appendEncKey +
    reverseString(encKey) +
    dataRkEnc(encKey, JSON.stringify(resObj));
  return finalString;
}

// ******************************************************************************** //
// For Decryption

function reverseStrings(str) {
  var splitString = str.split("");
  var reverseArray = splitString.reverse();
  var joinArray = reverseArray.join("");
  return joinArray;
}

export function gsk(auth) {
  var firstTF = auth.substring(0, 25);
  firstTF = firstTF.substr(firstTF.length - 20);
  firstTF = reverseStrings(firstTF);
  let lastTF = auth.substring(25);

  let data = aes256.decrypt(firstTF, lastTF);
  return data;
}

export const encryptionStorage = (data) => {
  const encrypt = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    DATA_KEY
  ).toString();
  return encrypt;
};

export const decryptionStorage = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, DATA_KEY);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const returnLocalStorageData = (pwd) => {
  let data = localStorage.getItem(pwd);
  if (data) {
    let dnzepto = aes256.decrypt(DATA_KEY, data);
    return dnzepto;
  } else {
    return false;
  }
};

export const returnEncrypt = (pwd) => {
  if (pwd) {
    let enpozito = aes256.encrypt(DATA_KEY, pwd);
    return enpozito;
  } else {
    return false;
  }
};

export const manipulation = () => {
  let userId = localStorage.getItem("userId");
  if (userId) {
    let item = userId.replace(/=/g, "cF");
    let item2 = item.replace(/F/g, "De6Gs");
    let user = reverseString(item2);
    return user;
  } else {
    return null;
  }
};

export const fixFloatNumber = (value) => {
  let parseIntValue = Number(value);
  if (Number.isInteger(parseIntValue)) {
    return parseIntValue;
  } else {
    return parseIntValue?.toFixed(2);
  }
};

export const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;

  return formattedDateTime;
};
