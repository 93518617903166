import { useNavigate } from "react-router-dom";
const DashboardBox = ({
  count,
  url,
  state,
  label,
  icon,
  className,
  isNewRequest,
  onClick,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className="col-xxl-3 col-xl-3 col-md-4 col-sm-6 col-12"
      style={{ cursor: "pointer" }}
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
        navigate(url, { state });
      }}
    >
      <div className="mat-mdc-card">
        <article className="icontext">
          <span className={`icon icon-sm rounded-circle ${className}`}>
            {icon}
          </span>
          <div className="text">
            <h6 className="mb-1">{label}</h6>
            <span>{count}</span>
          </div>

          {isNewRequest && isNewRequest > 0 ? (
            <span className="blink_me new_request_label">
              New {isNewRequest}
            </span>
          ) : (
            <></>
          )}
        </article>
      </div>
    </div>
  );
};
export default DashboardBox;
