import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      user_id: undefined,
      fullName: "User",
      isVerify: false,
      mobileNumber: "",
      role: "user",
      isPasswordChanged: false,
      balance: 0,
      loading: false,
      dashboardCount: {
        totalUsers: 0,
      },
    },
  },
  reducers: {
    setUserProfile(state, action) {
      const {
        _id,
        fullName,
        isVerify,
        mobileNumber,
        role,
        isPasswordChanged,
        balance,
      } = action.payload.user;
      state.user = {
        user_id: _id,
        balance,
        fullName,
        isVerify,
        mobileNumber,
        role,
        isPasswordChanged,
      };
    },
    onSetDashboardCounter(state, action) {
      state.dashboardCount = action.payload.counter;
    },
    onRequest(state) {
      state.loading = true;
    },
  },
});

export const { setUserProfile, onSetDashboardCounter, onRequest } =
  userSlice.actions;
export default userSlice;
