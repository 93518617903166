import { Fragment } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Styles from "../Styled/pageNotFound.module.css";

const PageNotFound = () => {
  return (
    <Fragment>
      <div className={Styles.page_error_container}>
        <div className={Styles.page_error_box}>
          <div>
            <h1 className={Styles.text_404}>OOPS!</h1>
            <br />
            <h2 className={Styles.not_found_text}>404 - PAGE NOT FOUND</h2>
            <p className={Styles.not_found_pera}>
              The page you are looking for might have been removed. <br /> It's
              name changed or temporarily unavailable.
            </p>
            <Link to={"/login"} className={Styles.back_to_home}>
              <IoMdArrowRoundBack />
              Back To Home
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PageNotFound;
