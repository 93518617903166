import { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import "../../Styled/Sidebar.scss";
import { RxDashboard } from "react-icons/rx";
import { FaRegUserCircle } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";

const Sidebar = () => {
  const userDropdownRef = useRef();
  const onCloseSideBarHandlerProps = () => {
  };


  return (
    <aside className="side-bar_bg " ref={userDropdownRef}>
      <div className="sidebar_box">
        <div className="side-bar-close-icon " onClick={onCloseSideBarHandlerProps}>
        <RiCloseLine />
        </div>
        <div className="logo-box">
          <Link to="#">
            <img
              className="dark"
              src={`/assets/images/logo/logo.png`}
              onClick={onCloseSideBarHandlerProps}
              alt="logo"
            />
          </Link>
        </div>

        <div className="manu_user_menu">
          <ul className="top_menu_bar">
            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "sidbar_menu_font active" : "sidbar_menu_font"
                }
                to="/dashboard"
                onClick={onCloseSideBarHandlerProps}
              > 
               <span className="icon_hos"> <RxDashboard  /></span>
                <span className="text-box">Dashboard</span>
              </NavLink>
            </li>

            <li>
              <NavLink
                className={({ isActive }) =>
                  isActive ? "sidbar_menu_font active" : "sidbar_menu_font"
                }
                to="/users"
                onClick={onCloseSideBarHandlerProps}
              >
                  <span className="icon_hos"> <FaRegUserCircle /></span>
                <span className="text-box">Users</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
