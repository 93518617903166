import React from "react";

const AppLogo = () => {
  return (
    <div className="logo_login_box">
      <img className="dark" src="/assets/images/logo/logo.png" alt="logo"></img>
    </div>
  );
};

export default AppLogo;
