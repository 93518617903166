import { useState } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.css";
import useAuthApp from "../hooks/useAuthApp";

const useDataTable = (tableRef, columns, columnDefs, initialBody, url) => {
  const { authFetch } = useAuthApp();
  const [tableData, setTableData] = useState([]);

  const initializeDataTable = async (isCallAPIInitially) => {
    try {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        $(tableRef.current).DataTable().destroy();
      }
      $(tableRef?.current).DataTable({
        pagingType: "full_numbers",
        pageLength: 50,
        paging: true,
        serverSide: true,
        responsive: true,
        lengthChange: true,
        order: [[0, ""]],
        lengthMenu: [50, 75, 100, 200],
        processing: true,
        language: {
          lengthMenu: "_MENU_",
          searchPlaceholder: "search",
          search: "",
          zeroRecords: "",
          infoFiltered: "",
          emptyTable: "No record found",
          paginate: {
            first: "<<",
            last: ">>",
            next: ">",
            previous: "<",
          },
        },
        ajax: async (data, callback) => {
          const requestData = {
            draw: 1,
            start: data.start,
            length: data.length,
            columns: data.columns,
            order: data.order,
            search: {
              value: data.search.value || "",
              regex: data.search.regex,
            },
            data: {
              page: Math.floor(data.start / data.length) + 1,
              limit: data.length,
              ...initialBody,
            },
          };
          try {
            if (isCallAPIInitially) {
              const { data } = await authFetch.post(url, requestData);
              if (data.status === true) {
                setTableData(data.data);
              }
              callback({
                recordsTotal: data.data?.pageCount || 0,
                recordsFiltered: data.data?.totalCount || 0,
                data: [],
              });
            } else {
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            }
          } catch (error) {
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }
        },
        columns: columns,
        columnDefs: columnDefs,
      });
    } catch (error) {
      console.error("Error initializing DataTable:", error);
    }
  };

  const onClickClearHandler = (body) => {
    initializeDataTable(body);
  };

  const updateDatatableData = (arr) => {
    setTableData(arr);
  };

  return {
    tableData,
    initializeDataTable,
    onClickClearHandler,
    updateDatatableData,
  };
};

export default useDataTable;
