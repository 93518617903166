import { ON_CONNECT_SOCKET } from "./actions"
import io from 'socket.io-client';

const reducer = (state, action) => {
  if (action.type === ON_CONNECT_SOCKET) {
    return {
      ...state,
      socket: io(action.payload.url, action.payload.option)
    }
  }
}

export default reducer