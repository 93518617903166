import axios from "axios";
import { apiBaseURL } from "../config/constance";
import { getEncKey, gsk, manipulation } from "../helper/common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { userLogoutOnSessionExpired } from "../store/auth/auth-action";
// import { onHandleUncaughtErrorFile } from "../helper/common";

const useAuthApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state) => state?.auth);

  let authFetch = axios.create({
    baseURL: `${apiBaseURL}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    transformRequest: [
      (data, headers) => {
        const encryptedString = getEncKey(data);
        data = {
          Auth: encryptedString,
        };
        return JSON.stringify(data);
      },
    ],
    transformResponse: [
      (response) => {
        response = JSON.parse(response);
        if (response.auth) {
          response = gsk(response.auth);
          return JSON.parse(response);
        } else {
          return response;
        }
      },
    ],
  });

  authFetch.interceptors.request.use(
    (config) => {
      config.headers.common["authorization"] = `${token}`;
      config.headers.common["Identity"] = manipulation();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  authFetch.interceptors.response.use(
    (response) => {
      if (
        response &&
        response?.data &&
        response?.data?.status === false &&
        response?.data?.statusCode === 401
      ) {
        let msg = response?.data?.message || "Session Expired.";
        if (msg === "Please be Authenticate.") {
          msg = "Session Expired.";
        }
        dispatch(userLogoutOnSessionExpired(navigate, msg));
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  let authFetchGET = axios.create({
    baseURL: `${apiBaseURL}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: `${token}`,
      Identity: manipulation(),
    },
    transformResponse: [
      (response) => {
        response = JSON.parse(response);
        if (response.auth) {
          response = gsk(response.auth);
          return JSON.parse(response);
        } else {
          return response;
        }
      },
    ],
  });

  authFetchGET.interceptors.response.use(
    (response) => {
      if (
        response &&
        response?.data &&
        response?.data?.status === false &&
        response?.data?.statusCode === 401
      ) {
        let msg = response?.data?.message || "Session Expired.";
        if (msg === "Please be Authenticate.") {
          msg = "Session Expired.";
        }
        dispatch(userLogoutOnSessionExpired(navigate, msg));
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    authFetch,
    authFetchGET,
    navigate,
    dispatch,
    location,
  };
};

export default useAuthApp;
