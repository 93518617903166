import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../Components/UI";
const ConfirmModal = ({
  isOpen,
  text,
  onConfirm,
  onCancle,
  modalTitle,
  loading,
}) => {
  return (
    <Modal size="xm" centered show={isOpen} onHide={onCancle}>
      <Modal.Header
        style={{ background: "#998773", color: "white" }}
        closeButton
      >
        <Modal.Title style={{ textTransform: "uppercase", fontSize: "17px" }}>
          {modalTitle}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ backgroundColor: "aliceblue" }} className="p-2">
        <div>
          <p style={{ fontSize: "17px" }}>{text}</p>
        </div>
      </Modal.Body>

      <Modal.Footer className="mt-1 p-1">
        <Button
          type="button"
          classes="btn btn-primary"
          disabled={loading}
          onClick={onConfirm}
        >
          {loading ? "Loading.." : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmModal;
