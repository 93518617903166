import React from "react";

const Button = ({ type, onClick, children, classes, isDisabled, title }) => {
  return (
    <button
      type={type || "button"}
      onClick={onClick}
      className={classes}
      disabled={isDisabled ? true : false}
      title={title}
    >
      {children}
    </button>
  );
};

export default Button;
